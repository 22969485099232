:root {
	/* Colours */
	--white: #ffffff;
	--black: #000000;
	--font-grey: #4c4d4e;
	/* Font Sizes */
	--extra-large-font: 45px;
	--large-font: 35px;
	--medium-font: 25px;
	--regular-font: 20px;
	--small-font: 15px;
	--extra-small-font: 11px;
	--font-regular-weight: 400;
	--main-font-family: "Montserrat", sans-serif;
	/* containers */
	--page-padding: 30px;
}

/* From App.css */
.App {
	text-align: center;
}

/* From index.css */
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/***** backgrounds *****/

.header-img {
	background-image: url("assets/images/landing/header-2.jpg");
	background-attachment: scroll;
	position: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100%;
	z-index: 0;
	filter: blur(8px);
	-webkit-filter: blur(8px);
}

.title-container {
	display: flex;
	align-items: center;
}

/*** background ***/

/***** Container Spacing *****/

.flex-center {
	display: flex;
	align-items: center;
}

.flex-center-center {
	justify-content: center;
	align-items: center;
	display: flex;
}

.line-spacer {
	border-bottom: 1px solid black;
	top: 50%;
	position: relative;
	height: 50%;
	width: 100%;
	display: inline-block;
}

.container-fluid {
	padding-left: var(--page-padding);
	padding-right: var(--page-padding);
	width: 100%;
	box-sizing: border-box;
}

.full-height {
	height: 100vh;
	padding-top: var(--page-padding);
	padding-bottom: var(--page-padding);
}

.mg-tp-0 {
	margin-top: 0;
}
.mg-bt-0 {
	margin-bottom: 0;
}

.modal-container {
	position: relative;
	z-index: 777;
	color: var(--font-grey);
	background: #f3f0f0;
	width: 100%;
	max-width: 480px;
	padding: 40px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
}

.container-one-third {
	width: 33%;
	display: inline-block;
}

/*** Container Spacing ***/

/***** Projects *****/

.code-block {
	background-color: var(--font-grey);
	padding: 5px 7px;
	color: var(--white);
	margin: 0 auto;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	border-radius: 6px;
}

@keyframes blink {
	0% {
		opacity: 0;
	}
	49% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

blink {
	animation: blink 1s infinite;
}

/* slider arrows */
.slick-arrow:before {
	color: var(--font-grey) !important;
}

.project-slide {
	display: flex !important;
	align-items: center;
	text-decoration: none;
	color: var(--font-grey);
	padding: 5px;
}

.project-slide .project-column-left {
	width: 49%;
	display: inline-block;
	text-align: left;
	padding: 5px;
}

.project-slide .project-column-left h3 {
	padding: 0;
	margin-top: 0;
}

.project-slide .project-column-right {
	width: 49%;
	display: inline-block;
	display: flex;
	align-items: center;
	height: 100%;
	margin: 5px;
}

.project-slide .project-image {
	width: 98%;
	box-shadow: 0 2px 5px 0px var(--font-grey);
}

/*** Projects ***/

/***** Footer *****/

.footer {
	z-index: 1000;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(57, 57, 57, 0.7);
	color: #fff;
}

.footer-link p {
	color: #fff;
	display: inline-block;
	margin: 0;
}

.footer-link a,
.footer-link a:visited,
.footer-link a:focus,
.footer-link a:active {
	color: #fff;
	position: relative;
	top: -17px;
}

.footer-link a:hover {
	font-weight: bold;
}

.footer-link img {
	height: 25px;
	width: 25px;
	margin: 15px 5px 10px 0;
	padding: 0;
}

/*** Footer ***/
